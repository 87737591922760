import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="message-view"

export default class extends Controller {
    static targets = ["section", "htmlContent", "deleteForm"]
    static classes = [ "hide" ]

    connect() {
        const content = this.htmlContentTarget.innerHTML.trim()
        const isEmpty = content.length <= 0
        // console.log("content isEmpty? ", isEmpty, " content = ", content.length)
        if (isEmpty) {
            this.sectionTarget.classList.add(this.hideClass)
        } else {
            this.sectionTarget.classList.remove(this.hideClass)
        }
    }

    messageSelected() {
        // the target section may be invisible, make it visible
        this.sectionTarget.classList.remove("invisible")
    }

    messageHideEvent(){
        this.htmlContentTarget.innerHTML = ""
        this.sectionTarget.classList.add(this.hideClass)
    }

    clickDelete(){
        console.log("clickDelete()")
        this.deleteFormTarget.requestSubmit()
        this.messageHideEvent()
    }
}
