import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="message-selection"
export default class extends Controller {
    static targets = ["form"]
    static outlets =  ['notification-list']
    static classes = [ "selected" ]

    static values = {
        id: Number,
    }

    connect() {
        // console.log("connect message-selection currentSelectedValue = ", this.notificationListOutlet.currentSelectedValue, " idValue = ", this.idValue)
        // TODO: set the class here
        if (this.notificationListOutlet.currentSelectedValue === this.idValue) {
            // console.log("adding class selected to ", this.idValue)
            this.element.classList.add(this.selectedClass)
        }
    }

    // selected means that the message is selected to be read
    // if the message is unread then we have to update the backend and read the message
    // so this is going to require an additional method of submitting the read form
    selected(event) {
        // console.log("selected() event target", event.target)
        const selected = event.target.closest('tr')
        // console.log("nearest tr", selected)
        const parentElement = selected.parentElement
        const children = parentElement.querySelectorAll('*');
        children.forEach(child => {
            child.classList.remove(this.selectedClass);
        });
        selected.classList.add(this.selectedClass)
        this.notificationListOutlet.currentSelectedValue = this.idValue
        // now we know the row we need to get the id of the message
        // console.log("selected id", this.idValue, "title ", this.titleValue)
        this.formTarget.requestSubmit()
        // console.log("message-selection:: after:selected() currentSelectedValue = ", this.notificationListOutlet.currentSelectedValue, " idValue = ", this.idValue)
    }
}
