import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="callout-handler"
export default class extends Controller {
  static targets = ["callout"]
  static values = {
    id: Number,
    viewTime: Number
  }
  connect() {
    this.viewTimeValue = this.viewTimeValue || 8
    console.log("CalloutHandlerController connected, view time = ", this.viewTimeValue)

    setTimeout(() => {
      console.log("CalloutHandlerController removing callout")
      this.calloutTarget.remove()
    }, this.viewTimeValue * 1000)
  }

  clickMarkRead(){
    console.log("CalloutHandlerController clickMarkRead")
    let crsf = document.querySelector("[name='csrf-token']") || {content: ""}
    fetch(`/profile/user_notifications/${this.idValue}.json`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": crsf.content
      },
      body: JSON.stringify({ status: "read" })
    })
  }
}
