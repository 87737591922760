import { Controller } from "@hotwired/stimulus"
import {splitFile} from "../src/utilities"

// Connects to data-controller="enquiry"
export default class extends Controller {
  static targets = [ "name", "extractFile" ]
  fileName() {
    const extract_file_name = this.nameTarget.value
    // console.log("extract file = ", extract_file_name)
    const name = splitFile(extract_file_name)
    this.extractFileTarget.value = name
  }
}
