import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clipboard"

export default class extends Controller {
    static targets = [ "source", "notifierOutput", "notifierTemplate" ]
    copy() {
        navigator.clipboard.writeText(this.sourceTarget.innerText).then(() => {
            this.showCopiedToast();
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
        // i need to show a foundation notification that says copied

    }
    showCopiedToast() {
        // Create the Toast notification
        const content = this.notifierTemplateTarget.content.firstElementChild.cloneNode(true);
        this.notifierOutputTarget.append(content)
        // set time to remove the notification
        const elem = $(content)
        setTimeout(() => {
            Foundation.Motion.animateOut(elem, 'slide-out-left', function(){
            });
        }, 1500); // Adjust time as needed
    }
}

