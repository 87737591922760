import {Controller} from "@hotwired/stimulus"
import consumer from "../channels/consumer";
import mustache from 'mustache';

// Connects to data-controller="notifier"
export default class extends Controller {
    static targets = ["globalNotificationTemplate", "globalNotificationOutput"]

    connect() {
        console.log("NotifierController binding to channel")
        this.subscription = consumer.subscriptions.create(
            {
                channel: "NoticeChannel",
                room: "all-eyes",
            },
            {
                connected: this._connected.bind(this),
                disconnected: this._disconnected.bind(this),
                received: this._received.bind(this),
            }
        );
    }

    _connected() {
    }

    _disconnected() {
    }

    _received(data) {
        console.log("notifierController received: ", data)
        // this._addGlobalNoticeItem(data)
        this._addGlobalNoticeItem(data)
    }

    _addGlobalNoticeItem(data) {
        let {title, content, user_notification_id} = data
        let viewTime = data.view_time || 11

        // const item =
        //     this.globalNotificationTemplateTarget.content.firstElementChild.cloneNode(true);
        const template = this.globalNotificationTemplateTarget.innerHTML
        const notificationHtml = mustache.render(template, {
            title: title,
            content: content,
            viewTime: viewTime,
            user_notification_id: user_notification_id
        })
        this.globalNotificationOutputTarget.insertAdjacentHTML('beforeend', notificationHtml);
    }
}
