// Entry point for the build script in your package.json
// Images are now with cssbundling and propshaft
// sass is now with jssbundling

// console.log("top of build");

import "core-js/stable";

// this is experimental and not used
// import * as ActiveStorage from '@rails/activestorage';
// ActiveStorage.start();
import("./channels");
import './src/add_jquery'
import Foundation from 'foundation-sites';
Foundation.addToJquery($);

import "@hotwired/turbo-rails"
// turn off navigation for now
Turbo.session.drive = false

import "./controllers"

import "./channels/index.js"
