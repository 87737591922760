import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="read-marker"
export default class extends Controller {
    static targets = ['form']
    static outlets =  ['notification-list', 'message-view']
    static values = {
        id: Number,
    }


    connect() {
    }

    // we have to keep this and have an event defined so
    // that we can stop propagation
    clickRead(event) {
        // not doing anything here
        // need this to stop the event propagating to the table
    }

    clickDelete(event) {
        // as well as allowing the form to post we need to
        // see if we are the currently selected message which is being deleted
        // change the message selection marker to -100
        if(this.notificationListOutlet.currentSelectedValue === this.idValue) {
            this.notificationListOutlet.currentSelectedValue = -100
            this.messageViewOutlet.messageHideEvent()
        }
    }
}
