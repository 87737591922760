import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="enquiry-modal"
export default class extends Controller {
    static targets = ["id", "name", "status", "response", "code"]

    connect() {
        // is this necessary - yes if foundation has not been initialized before
        $(this.element.parentNode).foundation();
    }

    view(id, name, status, response, code) {
        // this.idTarget.innerHTML = id
        this.nameTarget.innerHTML = name
        this.statusTarget.innerHTML = status
        this.responseTarget.innerHTML = response
        this.codeTarget.innerHTML = code
        $(this.element.parentNode).foundation('open')
    }
}
