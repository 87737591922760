import {Controller} from "@hotwired/stimulus"
import consumer from "../channels/consumer";

// Connects to data-controller="user"
export default class extends Controller {
    static targets = ["messageCount"]

    connect() {
        console.log("UserController binding to channel")
        consumer.subscriptions.create("UserChannel", {
            connected: this._connected.bind(this),
            disconnected: this._disconnected.bind(this),
            received: this._received.bind(this),
        });
    }

    _connected() {
    }

    _disconnected() {
    }

    _received(data) {
        console.log("userController received: ", data)
        this._showNotificationCount(data.user_notification_count)
    }

    _showNotificationCount(count) {
        this.messageCountTarget.innerHTML = count
    }
}
