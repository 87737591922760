import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="footer"
export default class extends Controller {
  connect() {
    this.initializeFoundation();
    this.positionFooterComponent();

  }

  initializeFoundation(){
    // console.log("stimulus: initializeFoundation()")
    $(document).foundation();
  }

  positionFooterComponent(){
    // console.log("stimulus: positionFooterComponent()");
    let contentHeight = $(window).height();
    let footerHeight = $(this.element).height();
    let footerTop = $(this.element).position().top + footerHeight;
    if (footerTop < contentHeight) {
      $(this.element).css('margin-top', 10 + (contentHeight - footerTop) + 'px');
    }

  }
}
