import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // this.element.textContent = "Hello connected and overwrite"
  }

  tested() {
    console.log("button clicked")

  }
}
