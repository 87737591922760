import {Controller} from "@hotwired/stimulus"
import Foundation from 'foundation-sites';
import jquery from "jquery";
// Connects to data-controller="enquiry-item"
export default class extends Controller {
    // static targets = ["firstName", "lastName", "code"]

    static values = {
        id: String,
        name: String,
        status: String,
        response: String,
        code: String
    }
    static outlets = ["enquiry-modal"]

    // connect() {
    //   setTimeout(() => {
    //     // console.log(this.enquiryModalOutlets)
    //     this.element.insertAdjacentHTML('beforeend', `. Found ${this.enquiryModalOutlets.length} outlets`);
    //   },100)
    //
    // }

    view() {
        // window.jQuery = jquery
        // window.$ = jquery

        // Foundation.addToJquery($);

        // console.log("lastName", this.lastNameTarget)
        // console.log("name", this.nameValue)
        // console.log("code", this.codeTarget)

        // console.log("hasEnquiryModalOutlet", this.hasEnquiryModalOutlet)
        // console.log("EnquiryModalOutlet", this.enquiryModalOutlet)
        // console.log("EnquiryModalOutletElement", this.enquiryModalOutletElement)
        // console.log("EnquiryModalOutletElement parentnode", this.enquiryModalOutletElement.parentnode)
        // console.log("parent jquery", this.getNode())
        // this.getNode().foundation();

        // $(this.enquiryModalOutletElement).foundation('open', 'reveal');
        // i have no idea why this works
        // send in data to the other controller to set fields
        this.enquiryModalOutlet.view(
            this.idValue,
            this.nameValue,
            this.statusValue,
            this.responseValue,
            this.codeValue,
        )
        //this move this to the outlet
        // $(this.getNode()).foundation('open');
        // this.getNode().foundation('open');

    }

    getNode() {
        return $(this.enquiryModalOutletElement.parentNode);
        // return $(this.enquiryModalOutletElement);
    }


}
